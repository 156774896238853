import React from 'react'
import { Element } from 'react-bulma-components'
import { useTranslation } from 'react-i18next'
import { useSession } from '../../stores/session'

const ProductPrices = ({
  product,
  customPrices
}) => {
  const [sessionState] = useSession()
  const { t } = useTranslation()
  return (
    <>
      {
        product && customPrices ?
          <Element textSize={7} renderAs='del'>€&nbsp;{product.pricing.price}<Element renderAs='span' textSize={7} textColor='grey'>&nbsp;{t('products:price')}</Element></Element>
          :
          <Element textSize={7}>&nbsp;</Element>
      }
      {
        !sessionState.currentUser ?
          <Element textSize={5}>
            €&nbsp;{product.pricing.srp}
          </Element>
          :
          product && product.kind === 'article' ?

            <Element textSize={5}>
              €&nbsp;{customPrices ? customPrices.PHTAX : product.pricing.price}
              <Element renderAs='span' textSize={6} textColor='grey'>&nbsp;{t('products:htt')}</Element>
              {
                customPrices &&
                  <>
                    <br />
                    <Element textSize={7}>SRP: {customPrices.SRP} €  </Element>
                  </>
              }
            </Element>
            :
            <Element>
              {/*
            sessionState.isAuthenticated &&
              <>
                <Element renderAs='p' textWeight='bold' mb={0}>Votre note</Element>
                <Rating
                  initialRating={ currentVote && currentVote.score }
                  emptySymbol={ <FontAwesomeIcon icon={faStarO} color='orange' /> }
                  fullSymbol={ <FontAwesomeIcon icon={faStar} color='orange' /> }
                  onChange={vote}
                />
              </>
          */}
            </Element>
      }

    </>
  )
}

export default ProductPrices
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Columns, Element, Form, Heading, Tag } from 'react-bulma-components'
import useLanguageSelector from '../../hooks/use-language-selector'
import { useSession } from '../../stores/session'
import CloudinaryImage from '../cloudinary/image'
import ProductQuantitySelector from '../products/quantity-selector'
import striptags from 'striptags'
import { useProducts } from '../../stores/products'
import ProductPrices from '../products/prices'
import AttemptVote from '../products/votes'
import ProductStock from '../products/stock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxes } from '@fortawesome/free-solid-svg-icons'

const SingleProductThumbnail = ({
  index,
  isNew,
  isPromo,
  product,
  customPrices,
  small,
  noPrices
}) => {
  const query = useStaticQuery(
    graphql`
      query productImages {
        allFile(filter: { relativeDirectory: { eq: "products" } }) {
          edges {
            node {
              relativeDirectory
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: CONTAIN }
                  width: 225
                  height: 225
                  backgroundColor: "transparent"
                )
              }
            }
          }
        }
      }
    `
  )

  const [sessionState, sessionActions] = useSession()
  const [productsState, productsActions] = useProducts()

  const language = useLanguageSelector().currentLanguage

  const currentVote =
    product &&
    product.kind === 'attempt' &&
    sessionState.currentUser &&
    product.votes.find(v => v.user === sessionState.currentUser._id)

  const vote = React.useCallback(
    async score => {
      await productsActions.attemptVote({
        productId: product._id,
        score,
      })
    },
    [product]
  )

  if (!product) return <></>

  return (
    <Element>
      <Element renderAs={Link} to={product && `/products/${product._id}`}>
        {isNew ? (
          <Tag color='primary' textWeight='bold'>
            Nouveauté !
          </Tag>
        ) : isPromo ? (
          <Tag color='warning' textWeight='bold'>
            Promo !
          </Tag>
        ) : (
          <Tag invisible></Tag>
        )}
        <Element textAlign='center'>
          <CloudinaryImage
            publicId={
              product.pictures[0] && product.pictures[0].item.providerId
            }
            transformations={{
              width: small ? '150' : '400',
              height: small ? '150' : '400',
              crop: 'pad',
            }}
          />
        </Element>
        <Heading
          textColor='black'
          size={small ? 7 : 6}
          textTransform='uppercase'
          textAlign={small && 'center'}
          textWeight='light'
          style={{ overflow: 'hidden', height: '2.8em' }}
        >
          {product ? product.name.fr : 'NOM DU PRODUIT'}
        </Heading>

        {!small && (
          <Heading
            subtitle
            mb={2}
            renderAs='p'
            textSize='7'
            style={{ height: '2rem', overflow: 'hidden' }}
          >
            {product.description &&
              product.description[language] &&
              striptags(
                product.description[language].substring(0, 100),
                [],
                '\n'
              )}
          </Heading>
        )}
      </Element>
      {!small && !noPrices && (
        <Columns>
          <Columns.Column>
            {product.kind === 'article' ? (
              <ProductPrices product={product} customPrices={customPrices} />
            ) : (
              <Element>
                <AttemptVote
                  product={product}
                  currentUser={sessionState.currentUser}
                  vote={vote}
                />
              </Element>
            )}
          </Columns.Column>
          <Columns.Column display='flex' alignItems='end'>
            <Element>
              <Element
                textAlign='right'
                textSize={7}
                mb={2}
                textColor={
                  product &&
                  product.stock.available &&
                'success'
                }
              >
                {product &&
                  <>
                    <Element renderAs='span' textColor='grey' mr={1}><FontAwesomeIcon icon={faBoxes}/></Element>
                    <ProductStock currentUser={sessionState.currentUser} stock={product.stock.available} />
                  </>
                }
                      &nbsp;
              </Element>
              {product.kind === 'article' && (
                <Form.Field kind='addons'>
                  <ProductQuantitySelector
                    basket={sessionState.basket}
                    productId={product._id}
                    onChange={sessionActions.addItemToBasket}
                  />
                </Form.Field>
              )}
            </Element>
          </Columns.Column>
        </Columns>
      )}
    </Element>
  )
}

export default SingleProductThumbnail
